import { ProfileModel } from '@agility/frameworkcore';

export class DeclarationModel extends ProfileModel {
  public declarationId: number;
  public billReason?: number;
  public declarationCategory?: number;
  public declarationNumber: string;
  public declarationDate?: Date;
  public consigneeId?: number;
  public consigneeType: string;
  public referenceDeclarationId?: number;
  public customsValue?: number;
  public totalCIF?: number;
  public totalFoB?: number;
  public rmSChannel: string;
  public declarationSourceId?: number;
  public totalDuty?: number;
  public totalSurity?: number;
  public totalPayable?: number;
  public tariffDate?: Date;
  public approvedDate?: Date;
  public releasedDate?: Date;
  public originLocationTypeId?: number;
  public declaredValue?: number;
  public rejectionRemarks: string;
  public declarationForId?: number;
  public totalDutyAmount?: number;
  public dutiableValue?: number;
  public nonDutiableValue?: number;
  public rmSCleared: string;
  public funUnitFormId?: number;
  public rmSBaseChannel: string;
  public wareHouseId?: number;
  public beneficiaryId?: number;
  public destinationPort?: number;
  public movementTypeId?: number;
  public transitRouteId?: number;
  public originLocationId?: number;
  public destinationLocationId?: number;
  public originCustomStationId?: number;
  public destinationCustomStationId?: number;
  public finalDestinationId?: number;
  public movementAcknowledged: string;
  public declarationType: string;
  public subcontractTypeId?: number;
  public isAcknowledged: string;
  public acknowledgedDate?: Date;
  public acknowledgedBy: string;
  public subContractedMoreThan30: string;
  public carnetStartDate?: Date;
  public carnetExpiryDate?: Date;
  public countryofOrigin?: number;
  public declarationReason?: number;
  public isBulkItem?: boolean;
  public declarationExpiryDate?: Date;
  public submittedDate?: Date;
  public dataVersion?: number;
  public originWarehouseStationId?: number;
  public isCRTCompleted: string;
  public trackingNo: string;
  public totalExemptions?: number;
  public liJettyInfoId?: number;
  public isDeferPayment: string;
  public licenseInfoId?: number;
  public agentId?: number;
  public originLicenseId?: number;
  public billofLadingId?: number;
  public modeoftransport?: number;
  public declarationCloseDate?: Date;
  public flagDifferCheck: string;
  public consignor: string;
  public loadTypeId: number;
  public consigneeName: string;
  public stationCode: any;
  public stationName: any;
  public referenceDeclarationNumber: any;
  public functionalUnitReferenceNumber: any;
  public countryName: any;
  public billOfLadingNumber: any;
  public consigneeTypes: string;
  public declarationTransactionType: any;
  public declarationTransactionTypeCode: any;
  public isShipmentAssociated: boolean;
  public iSk1AFormCompleted: boolean;
  public declarationSourceCode: any;
  public invoiceAmount?: number;
  public billTypeCode: string;
  public isDcoumentUploaded?: boolean;
  public isBlLineAssociated: boolean;
  public billType?: number;
  public customsControlProcedureId?: number;
  public tempDeclarationNumber: string;
  public declarationSourceName: string;
  public destinationLocationTypeId?: number;
  public customStationId?: number;
  public bwSFormId?: number;
  public buyerNationality?: number;
  public buyerIDNumber: string;
  public buyerName: string;
  public ownerCSId: number;
  public isDeclDocumentsAvailable: boolean;
  public consignorName: any;
  public modeOfTransportName: any;
  public declarationSources: any;
  public invoiceList: any;
  public referenceDeclarationViewModels: any;
  public consignorId: any;
  public consignorTypes: string;
  public movementType: any;
  public finalDestination: any;
  public originLocation: any;
  public destinationLocation: any;
  public originCustomStation: any;
  public destinationCustomStation: any;
  public transitRoute: any;
  public warehoueNumber: any;
  public originTypeCode: any;
  public destinationTypeCode: any;
  public warehouseFormModel: any;
  public journeyId?: number;
  public modeofTransportName: string;
  public journeyNumber: any;
  public countryofOriginName: string;
  public rmsChannel: any;
  public statusClass: any;
  public scDeclarationId: number;
  public refScDeclarationNumber: any;
  public scDeclarationNumber: string;
}

export class TransportMode {
  public typeId: number;
  public name: string;
  public className: string;
}
