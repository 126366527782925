import { ProfileModel } from '@agility/frameworkcore';

export class TariffitemViewModel extends ProfileModel {
  tariffItemId: number;
  tariffCode: string;
  description: string;
  uom1: number;
  uom2: number;
  uom3: number;
  name: string;
  startDate: Date;
  endDate: Date;
  localDescription: string;
  infiniteDate: boolean;
  isTRQ: boolean;
  ownerCSId: number;
}
