import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ServiceDocument } from '@agility/frameworkcore';
import { ToasterService } from '../../../toastrService/toasterService';
import { DeclarationDocumentModel } from '../../models/requiredDocumentModels/DeclarationDocumentModel';
import { DeclarationSharedService } from '../sharedServices/DeclarationSharedService';
import { map, catchError } from 'rxjs/operators';
import { McnConfig } from '../../../mcnConfig/McnConfig';
import { DeclarationMessages } from '../../declarationConstant/Constant';


@Injectable()
export class DeclarationDocumentService {
  serviceDocumentDeclDocumentService: ServiceDocument<DeclarationDocumentModel> = new ServiceDocument<DeclarationDocumentModel>();

  constructor(
    public appConfig: McnConfig,
    private toastrService: ToasterService,
    private sharedService: DeclarationSharedService,

  ) { }

  private handleErrorDeclDocumentService() {
    return this.toastrService.Error(this.appConfig.getHardCodeMSg(DeclarationMessages.InternalServerErrorPleaseTryAgainLater));
  }

  public listDeclDocumentService(): Observable<ServiceDocument<DeclarationDocumentModel>> {
    return this.serviceDocumentDeclDocumentService.list(this.appConfig.declarationDocumentList,
      new HttpParams().set('declarationId', this.sharedService.sharingData.declarationId.toString()))
      .pipe(map((response) => response),
        catchError(() => throwError({ msg: this.handleErrorDeclDocumentService(), ngNavigationCancelingError: true })));
  }

  public newDeclDocumentService(): Observable<ServiceDocument<DeclarationDocumentModel>> {
    return this.serviceDocumentDeclDocumentService.new(this.appConfig.newDeclarationDocument)
      .pipe(map((response) => response),
        catchError(() => throwError({ msg: this.handleErrorDeclDocumentService(), ngNavigationCancelingError: true })));
  }

  public openDeclDocumentService(id: number): Observable<ServiceDocument<DeclarationDocumentModel>> {
    return this.serviceDocumentDeclDocumentService.open(this.appConfig.openDeclarationDocument,
      new HttpParams().set('id', this.sharedService.sharingData.declarationId.toString()))
      .pipe(map((response) => response),
        catchError(() => throwError({ msg: this.handleErrorDeclDocumentService(), ngNavigationCancelingError: true })));
  }


  deleteDeclDocumentService(): Observable<ServiceDocument<DeclarationDocumentModel>> {
    return this.serviceDocumentDeclDocumentService.delete(this.appConfig.deleteDeclarationDocument)
      .pipe(map((response) => response),
        catchError(() => throwError({ msg: this.handleErrorDeclDocumentService(), ngNavigationCancelingError: true })));
  }

  public submitDeclDocumentService(): Observable<ServiceDocument<DeclarationDocumentModel>> {
    return this.serviceDocumentDeclDocumentService.submit(this.appConfig.submitDeclarationDocument)
      .pipe(map((response) => response),
        catchError(() => throwError({ msg: this.handleErrorDeclDocumentService(), ngNavigationCancelingError: true })));
  }
}

