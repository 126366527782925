import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ServiceDocument } from '@agility/frameworkcore';
import { ShipmentOrderService } from './ShipmentOrderService';
import { ShipmentOrderModel } from './models/ShipmentOrderModel';

@Injectable()
export class ShipmentOrderListResolver implements Resolve<ServiceDocument<ShipmentOrderModel>> {
  constructor(private service: ShipmentOrderService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<ShipmentOrderModel>> {
    return this.service.shipmentOrderList('', '');
  }
}

@Injectable()
export class ShipmentOrderNewResolver implements Resolve<ServiceDocument<ShipmentOrderModel>> {
  constructor(private service: ShipmentOrderService) { }
  resolve(): Observable<ServiceDocument<ShipmentOrderModel>> {
    return this.service.newShipmentOrder();
  }
}

@Injectable()
export class ShipmentOrderOpenResolver implements Resolve<ServiceDocument<ShipmentOrderModel>> {
  constructor(private service: ShipmentOrderService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<ShipmentOrderModel>> {
    return this.service.openShipmentOrder(route.params['id']);
  }
}

