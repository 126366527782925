import { ProfileModel } from '@agility/frameworkcore';
export class ServiceModel extends ProfileModel {
  serviceId: number;
  serviceNumber: string;
  receiptForId: number;
  name: string;
  description: string;
  serviceGroupId: number;
  serviceNameId: number;
  serviceTypeId: number;
}
