import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { McnConfig } from '../../../mcnConfig/McnConfig';
import { ToasterService } from '../../../toastrService/toasterService';
import { map, catchError } from 'rxjs/operators';
import { SezConfig } from '../../../mcnConfig/SezConfig';

@Injectable()
export class SezWarehouseVehicleService {
  public headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private appConfig: McnConfig,
    private sezConfig: SezConfig,
    private toastrService: ToasterService,
    private http: HttpClient,
  ) { }

  private handleError() {
    return this.toastrService.Error('Internal Server Error.Please Try Again Later.');
  }

  public filterItems(
    itemNumber: string,
    itemHSCode: string,
    associatedWHItemId: any[],
    pageIndex: number = 1,
    recordPerPage: number = this.appConfig.appData.associatePageCount): Observable<any> {
    const listWarehouseVehicleModel = {
      associatedWHItemId: associatedWHItemId,
      isDescending: true,
      orderIndex: 0,
      pageIndex: pageIndex,
      recordPerPage: recordPerPage,
      itemNumber: itemNumber,
      itemHSCode: itemHSCode
    };
    return this.http.post(this.sezConfig.filterWarehouseVehicles, listWarehouseVehicleModel)
      .pipe(map((response) => response),
        catchError(() => throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public listAssociatedVehicles(id: number): Observable<any> {
    return this.http.get(this.sezConfig.listAssociatedWarehouseVehicles + '?vehMovementRequestId=' + id, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public submitAssoVehicles(associatedVehList: any[], vehMovementRequestId: number): Observable<any> {
    return this.http
      .post(`${this.sezConfig.submitAssocWarehouseVehicles}?vehMovementRequestId=${vehMovementRequestId}`,
        associatedVehList, { headers: this.headers })
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }
}
