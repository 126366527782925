import { ProfileModel } from '@agility/frameworkcore';

export class DrawbackFacilityApprovalModel extends ProfileModel {
    drawbackFacilityApprovalId: number;
    isFactoryAddressSame?: boolean;
    address: string;
    postalCodeId?: number;
    drawbackFacilityApprovalReqNumber: string;
    reportingRemarks: string;
    assignedTo?: number;
    recordKeeping?: number;
    remarks: string;
    siteVisitDate?: Date;
    approvalDate?: Date;
    cityId?: number;
    consigneeId?: number;
    customsStationId?: number;
    address2: string;
    address3: string;
    nmPostalcode: string;
    nmCity: string;
    nmState: string;
    countryId?: number;
    nmCustomStation: string;
    ownerCSId?: number;
    masterCustomsStationId?: number;
    submittedDate?: Date;
    stateId?: number;
    expectedDate?: Date;
    drawbackFacilityApprovalCategoryId?: number;
    drawbackFacilityApprovalCategory: string;
    organizationName: string;
    brnNumber: string;
    stateName: string;
    customStation: string;
    reasonId?: number;
    returnForCorrectionRemarks: string;
    isDrawbackDocumentAvailable?: boolean;
    agencyName: string;
}
