import { Injectable } from '@angular/core';
import 'rxjs/Rx';

export interface PaymentsData {
  paymentId: number;
  billId: number;
  paymentTransactionId: number;
  PaymentBillDetails: boolean;
  AssociateBills: boolean;
  addpaymentTransactiondet: boolean;
  editbillList: boolean;
  makePayment: string;
  suretyAmount: number;
  suretyRefNumber: string;
  suretyTransactionCount: number;
  suretySourceTypeId: number;
  ownerCSId: number;
  associatedAmount: number;
  associatedList: any;
  associatedVoucherList: any;
  paymentTransactionCount: number;
  paymentTransactionRefundCount: number;
  associatedSuretyTransacDetailsCount: number;
  voucherId: number;
  paymentReceiptId: number;
  paymentTransactionNo: string;
  selectedPassengerAssessmentId: number;
  refundForPassengerId: number;
  refundForPassengerStatus: string;
  isOpenedFromBill: boolean;
}

@Injectable()
export class PaymentCommonService {
  public sharingData: PaymentsData = {
    paymentId: 0, billId: 0, paymentTransactionId: 0, PaymentBillDetails: false,
    AssociateBills: false, addpaymentTransactiondet: false, editbillList: false, makePayment: '', suretyAmount: 0,
    suretyRefNumber: '', suretyTransactionCount: 0, suretySourceTypeId: 0, ownerCSId: 0, associatedAmount: 0,
    associatedList: null, associatedVoucherList: null, paymentTransactionCount: 0, paymentTransactionRefundCount: 0,
    associatedSuretyTransacDetailsCount: 0, voucherId: 0, paymentReceiptId: 0, paymentTransactionNo: null,
    selectedPassengerAssessmentId: 0, refundForPassengerId: 0, refundForPassengerStatus: null, isOpenedFromBill: false
  };

  constructor() {
  }
}
