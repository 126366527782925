﻿import { ProfileModel } from '@agility/frameworkcore';
import { McnTypesModel } from '../../../mdm/models/McnTypesModel';
import { TariffRateQuotaDetailsModel } from '../tariffRateQuotaDetailsModel/TariffRateQuotaDetailsModel';

export class TariffRateQuotaModel extends ProfileModel {
    public tariffRateQuotaId: number;
    public quotaRefCode: string;
    public validFrom?: Date;
    public validTo?: Date;
    public quotaName: string;
    public quotaCondition?: number;
    public canViewQuota: string;
    public remarks: string;
    public submittedDate?: Date;
    public approvedDate?: Date;
    public reduceQuota: boolean;
    public changeConditions: boolean;
    public changeValidity: boolean;
    public cancellationReason?: number;
    public cancellationRemarks: string;
    public defaultPosting?: number;
    public createdDate: Date;
    public requestAddQuota: boolean;
    public quotaConditionData: McnTypesModel;
    public quotaDirectionData: McnTypesModel;
    public tariffRateQuotaDetails: TariffRateQuotaDetailsModel[];
    public trqOrganizations: number;
    public quotaBalance: number;
    public applicableQuota: number;
    public tariffRateQuotaData: any;
    public quotaType: any;
    public tariffRateQuotaCount: any;
    public absoulteRateQuotaCount: any;
    public direction?: number;
}
