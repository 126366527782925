import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timeFormatFor12Hours' })
export class TimeFormatFor12HoursPipe implements PipeTransform {
  transform(dateTime: any): any {
    if (dateTime != null) {
      const time = dateTime.split('T')[1].split('.')[0];
      if (time != null) {
        let hour = (time.split(':'))[0];
        let min = (time.split(':'))[1];
        const part = hour > 12 ? 'PM' : 'AM';
        min = (min + '').length === 1 ? `0${min}` : min;
        hour = hour > 12 ? hour - 12 : hour;
        hour = (hour + '').length === 1 ? `0${hour}` : hour;
        return `${hour}:${min} ${part}`;
      }
    }
  }
}

