import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ServiceDocument } from '@agility/frameworkcore';
import { WarehouseFormsModel } from '../../sezModels/SezWarehouseFormsModel';
import { SezWareHouseService } from './SezWareHouseService';
import { SezWHFormItemsService } from './SezWHFormItemsService';
import { ListWarehouseItemsModel } from '../../sezModels/SezListWarehouseItemsModel';

@Injectable()
export class WareHouseListResolver implements Resolve<ServiceDocument<WarehouseFormsModel>> {
  constructor(private service: SezWareHouseService) { }
  public resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<WarehouseFormsModel>> {
    return this.service.list('', '');
  }
}

@Injectable()
export class WareHouseNewResolver implements Resolve<ServiceDocument<WarehouseFormsModel>> {
  constructor(private service: SezWareHouseService) { }

  public resolve(): Observable<ServiceDocument<WarehouseFormsModel>> {
    return this.service.new();
  }
}

@Injectable()
export class WareHouseOpenResolver implements Resolve<ServiceDocument<WarehouseFormsModel>> {
  constructor(private service: SezWareHouseService) { }

  public resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<WarehouseFormsModel>> {
    return this.service.open();
  }
}

@Injectable()
export class WHFormItemsListResolver implements Resolve<ServiceDocument<ListWarehouseItemsModel>> {
  constructor(private service: SezWHFormItemsService) { }
  public resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<ListWarehouseItemsModel>> {
    return this.service.list('', '');
  }
}
