import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../../storageService/storageService';
import { SafeUnsubscriber } from '../../../../common/SafeUnsubscriber';

@Component({
  selector: 'app-custom-modal',
  templateUrl: './addManifestModalComponent.html',

})
export class addManifestModalComponent extends SafeUnsubscriber implements OnInit {
  public reasons = [{ value: 'Reason', viewValue: 'Reason 1' },
  { value: 'Reason2', viewValue: 'Reason 2' }, { value: 'Reason3', viewValue: 'Reason 3' }];

  constructor(
    public storageService: StorageService,
  ) { super(); }

  ngOnInit() {
  }

  public associateManifestData(event: any) {

  }
}
