import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ServiceDocument } from '@agility/frameworkcore';
import { WarehouseFormsModel } from '../../models/WarehouseFormsModel';
import { WareHouseService } from './WareHouseService';
import { WHFormItemsService } from './WHFormItemsService';
import { ListWarehouseItemsModel } from '../../models/ListWarehouseItemsModel';

@Injectable()
export class WareHouseListResolver implements Resolve<ServiceDocument<WarehouseFormsModel>> {
  constructor(private service: WareHouseService) { }
  public resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<WarehouseFormsModel>> {
    return this.service.list('', '');
  }
}

@Injectable()
export class WareHouseNewResolver implements Resolve<ServiceDocument<WarehouseFormsModel>> {
  constructor(private service: WareHouseService) { }

  public resolve(): Observable<ServiceDocument<WarehouseFormsModel>> {
    return this.service.new();
  }
}

@Injectable()
export class WareHouseOpenResolver implements Resolve<ServiceDocument<WarehouseFormsModel>> {
  constructor(private service: WareHouseService) { }

  public resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<WarehouseFormsModel>> {
    return this.service.open();
  }
}

@Injectable()
export class WHFormItemsListResolver implements Resolve<ServiceDocument<ListWarehouseItemsModel>> {
  constructor(private service: WHFormItemsService) { }
  public resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<ListWarehouseItemsModel>> {
    return this.service.list('', '');
  }
}
